import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import axios from "axios";
import './i18n';

axios.defaults.baseURL= (process.env.REACT_APP_BE_BASE_URL);
axios.defaults.withCredentials=true;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Suspense><App /></Suspense>);
