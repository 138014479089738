import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import thumb from '../images/acdd.jpg';
import Image from 'react-bootstrap/Image';
import YouTube from 'react-youtube';
import "../styles/page.css";
import Carousel from 'react-bootstrap/Carousel';
import aglione2 from '../images/aglione-nobg.png';
import crema1 from '../images/crema1.jpeg';
import crostino from '../images/crostinoaglione.png';
import divulgazione from '../images/divulgazione-terra.jpeg';
import dante from '../images/dante.png';
import televisione from '../images/televisione.jpeg';
import aglione3 from '../images/aglione3.jpeg';
import '../styles/home.css'
import Products from '../components/ProductsList';
import ProductsShowCase from '../components/ShowcaseComponent';
import CloseButton from 'react-bootstrap/esm/CloseButton';
import { useState } from 'react';
import YoutubeComponent from '../components/YoutubeComponent';
import { CO } from 'country-flag-icons/react/3x2';

export default function HomePage() {

  const carouselHeight = "50vh";
  const captionHeight = "20vh";
  return (
            <Container>
              <Row>
                <div className='home-header'>
                  <Row>
                    <Col>
                    <div class="text"><h1>A Casa di Dante</h1>
                <h2>Dove venire a scoprire i sapori della terra 
                prodotti a Km 0</h2> 
                <p>L'obiettivo di Dante è quello di portare nelle vostre case i sapori della tradizione.</p>
                <p>Consigliarvi nella preparazione di piatti che nella loro semplicità sprigionano la vera essenza della terra. </p>
                <p>

                </p>
                </div>
                    </Col>
                    <Col>
                    <img
                    src={divulgazione} style={{}}
                  />
                    </Col>
                  </Row>


                </div>
              </Row>

  <Row>
<div class="outline">
<h1 style={{color:'white'}}>DIVULGAZIONE</h1>
</div>

  </Row>
              <Row>

                <Col>
                <a href='https://mediasetinfinity.mediaset.it/video/studioapertomag2024/laglio-del-bacio_F312992101148C01'>
              <img
                    src={televisione}
                  />
              </a>
                </Col>
                <Col>
                    <div class="text">
                <p>Una delle misioni perseguite da Dante è quella di trasmettere la propria passione 
                  attraverso la divulgazione delle materie che lo incuriosiscono maggiormente.
                  Insegnare il rispetto per la natura e esaltare i prodotti della campagna con l'occhio critico di chi l'ha sempre vissuta.</p>

                </div>
                    </Col>

              </Row>
              <Col>



                </Col>
              <Row> 
              <div class="outline">
<h1 style={{color:'white'}}>CUCINA</h1>
</div>
              </Row>
              <Row>
                <Col>
                <div class="text">
                <p>Dante ha realizzato per voi una trasmissione di cucina a km 0, dove si parla di territorio, di prodotti, portando a tavola i sapori genuini di un tempo</p>

                </div>

                </Col>
                <Col>
                <YouTube videoId="QZVSX2BwNO4?si=jKPudMHgygLBz4P5"/>
                </Col>
              </Row>
              <Row>
                <Col>

                </Col>
                <Col>

                </Col>
              </Row>

        </Container>
  )
}
