import create from "zustand";
import axios from "axios";

const authStore = create((set) => ({
    loggedIn:null,
    isAdmin: null,
 loginForm:{
    email:"",
    password:"",
 },
 signupForm:{
    email:"",
    password:"",
 },
 updateLoginForm:(e)=>{
    const{name,value}= e.target;

    set((state)=>{
        return{
            loginForm:{
                ...state.loginForm,
                [name]:value,
            },
        };
    });
 
 },
 updateSingupForm:(e)=>{
    const{name,value}= e.target;

    set((state)=>{
        return{
            signupForm:{
                ...state.signupForm,
                [name]:value,
            },
        };
    });
 
 },
login: async ()=>{


    const{loginForm}= authStore.getState();
    const res= await axios.post("/login", loginForm, {withCredentials:true});

    console.log(res.data.user.admin);

    set({loggedIn:true, isAdmin: res.data.user.admin});

},
checkAuth: async ()=>{
    try{
        const res=await axios.get("/check-auth", {withCredentials:true});
        console.log(res);
        set({loggedIn:true});
        set({isAdmin: res.data.admin});
    }catch(err){
        set({loggedIn:false});
    }

},
signup: async ()=>{

    const{signupForm}= authStore.getState();
    const res= await axios.post("/signup", signupForm, {withCredentials:true});
},
logout: async ()=>{
    await axios.get("/logout");
    set({loggedIn:false});
}
}));



export default authStore;
