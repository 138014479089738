import LoginPage from "../pages/LoginPage";
import {BrowserRouter,Routes,Route,useParams} from "react-router-dom"
import RequireAuth from "./RequireAuth";
import SignUpPage from "../pages/SignUpPage";
import LogoutPage from "../pages/LogoutPage";
import NavbarComp from "./Navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import StorePage from "../pages/StorePage";
import ProductPage from "../pages/ProductPage";
import HomePage from "../pages/HomePage";
import CartPage from "../pages/CartPage";
import "../styles/app.css"
import AdminPage from "../pages/AdminPage";
import RequireAdmin from "./RequireAdmin";

function App() {

  return (
    <div className="App">

      <BrowserRouter>
      <NavbarComp/>
      <Routes>
        {/*        <Route index element={<RequireAuth><NotesPage /></RequireAuth>}/> */}
        <Route index element={<HomePage />}/>

        <Route relative path="/admin" element={<RequireAdmin><AdminPage/></RequireAdmin>}/>
        <Route relative path="/login" element={<LoginPage/>}/>
        <Route relative path="/signup" element={<SignUpPage/>}/>
        <Route relative path="/logout" element={<LogoutPage/>}/>
        <Route relative path="/store" element={<StorePage/>}/>
        <Route relative path="/product/:_id" element={<ProductPage/>}/>
        <Route relative path="/cart" element={<CartPage/>}/>
      </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
