import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import authStore from "../stores/authStore"

export default function RequireAdmin(props) {
    const store =authStore();

    console.log(store);
    useEffect(()=>{
        if(store.isAdmin==null){
            store.checkAuth();
        }
    },[]);
    if(store.isAdmin === null){
        return<div>Loading</div>;
    }
    if(store.isAdmin === false){
        return <Navigate to= "/login"/>;
    }
  return (
    <div>{props.children}</div>
  )
}
