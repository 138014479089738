import React from 'react'
import SignUpForm from '../components/SignUpForm'

export default function SignUpPage() {
  return (
    <div><h1>Signup</h1>
    <SignUpForm/>
    </div>
  )
}
