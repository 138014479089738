import Container from 'react-bootstrap/Container';
import DetectableOverflow from 'react-detectable-overflow';
import { useEffect, useState } from "react";
import ShowMoreText from "react-show-more-text";
import "../styles/card.css"
import Products from '../components/ProductsList';



export default function StorePage() {

    const [overflow, setOverflow] = useState(false);
  return (
    <Container>
          <h1>Prodotti:</h1>
        <Products/>
    </Container>
  )
}
