import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NotesPage from "../pages/notesPage";
import LoginPage from "../pages/LoginPage";
import {BrowserRouter,Routes,Route,Link} from "react-router-dom"
import RequireAuth from "./RequireAuth";
import SignUpPage from "../pages/SignUpPage";
import LogoutPage from "../pages/LogoutPage";
import { FaCartShopping } from "react-icons/fa6";
import { IoLogOutOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { CiShop } from "react-icons/ci";
import { FaHome } from "react-icons/fa";
import Badge from 'react-bootstrap/Badge';
import ordersStore from '../stores/ordersStore';
import { useEffect } from 'react';
import NoAuth from './NoAuth';
import IsAuth from './IsAuth';
import melina from '../images/melina.png';
import "../styles/navbar.css"
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import Flags from 'country-flag-icons/react/3x2';
import FlagsComponent from './TranslationComponent';
import { SlSocialInstagram } from "react-icons/sl";
import { TiSocialYoutube } from "react-icons/ti";
import { SlSocialFacebook } from "react-icons/sl";
        {/*    https://react-icons.github.io/react-icons/ */}
function NavbarComp() {
  const languages = {

    en: {nativeName: 'English'},
    it: {nativeName: 'Italiano'}
  };
  const {t, i18n} = useTranslation();
  /*const order = ordersStore();

useEffect(() => {
  order.fetchOrders();
}, []);*/

  return (
<Navbar sticky='top' expand="lg" className="bg-body-tertiary" bg="dark" data-bs-theme="dark">
      <Container>
        <Navbar.Brand href="./"             style={{ display: 'flex', justifyContent:"space-evenly", verticalAlign:"center" }}>            
        <div className='brand'><img
              src={melina}
            />
            <h1>A Casa di Dante</h1></div></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/*<Nav.Link href="./"><FaHome /> Home</Nav.Link>
            <NoAuth><Nav.Link href="./login"><FaRegUser /> Login</Nav.Link></NoAuth>
            <Nav.Link href="./store"><CiShop />{t('store')}</Nav.Link>
            <NoAuth><Nav.Link href="./signup">Registrazione</Nav.Link></NoAuth>
            <IsAuth><Nav.Link href="./cart"><FaCartShopping /><Badge pill bg="danger">{order.orders.length>0 ? order.orders.length : null}</Badge> Carrello</Nav.Link></IsAuth>
            <IsAuth><Nav.Link href="./logout"><IoLogOutOutline /> Logout</Nav.Link></IsAuth></Navbar.Collapse>*/}
          </Nav>
          <a href='https://www.facebook.com/acasadidante'>          <SlSocialFacebook/></a>

          <a href='https://www.instagram.com/acasadidante/'>          <SlSocialInstagram/></a>

          <a href='https://www.youtube.com/playlist?list=PLWCULRqTmSBJjUUb5eDwHfBHnhuAbIPPW'>
          <TiSocialYoutube/>
          </a>

          <FlagsComponent></FlagsComponent>
        </Navbar.Collapse>

      </Container>
    </Navbar>
  );
}

export default NavbarComp;