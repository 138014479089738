import React from 'react'
import authStore from "../stores/authStore"

export default function SignUpForm() {
    const store = authStore();

    const handleSignup = async (e)=>{
        e.preventDefault();
        await store.signup();

    };

  return (
    <form onSubmit={handleSignup}>
        <input onChange={store.updateSingupForm} value={store.signupForm.email} type="email" name="email"/>
        <input onChange={store.updateSingupForm} value={store.signupForm.password} type="password" name="password"/>
        <button type='submit'>Signup</button>
    </form>
  )
}
