import create from "zustand";
import axios from "axios";

const ordersStore = create((set) => ({
  orders: [],

  createForm: {
    productId: "",
    quantity: 1,
  },

  updateForm: {
    productId: "",
    quantity: 1,
  },

  fetchOrders: async () => {
    // Fetch the Orders
    const res = await axios.get("/orders");

    console.log(res.data);
    // Set to state
    set({ orders: res.data.orders });
  },

  updateCreateFormField: (e) => {
    const { name, value } = e.target;

    set((state) => {
      return {
        createForm: {
          ...state.createForm,
          [name]: value,
        },
      };
    });
  },

  createOrder: async (e) => {
    e.preventDefault();

    const { createForm, orders } = ordersStore.getState();
    const res = await axios.post("/orders", createForm);

    set({
      orders: [...orders, res.data.orders],
      createForm: {
        productId: "",
        quantity: 1,
      },
    });
  },

  deleteOrder: async (_id) => {
    // Delete the Order
    await axios.delete(`/orders/${_id}`);
    const { orders } = ordersStore.getState();

    // Fetch the Orders
    const res = await axios.get("/orders");

    console.log(res.data);
    // Set to state
    set({ orders: res.data.orders });
  },

  handleUpdateFieldChange: (e) => {
    const { value, name } = e.target;

    set((state) => {
      return {
        updateForm: {
          ...state.updateForm,
          [name]: value,
        },
      };
    });
  },

  toggleUpdate: ({ _id, title, description,price }) => {
    set({
      updateForm: {
        title,
        description,
        price,
        _id,
      },
    });
  },

  updateOrder: async (e) => {
    e.preventDefault();

    const {
      updateForm: { title, description,price, _id },
      Orders,
    } = ordersStore.getState();

    // Send the update request
    const res = await axios.put(`/orders/${_id}`, {
      title,
      description,
        price,
    });

    // Update state
    const newOrders = [...Orders];
    const OrderIndex = Orders.findIndex((Order) => {
      return Order._id === _id;
    });
    newOrders[OrderIndex] = res.data.Order;

    set({
      Orders: newOrders,
      updateForm: {
        _id: null,
        title: "",
        description: "",
        price:0.0,
      },
    });
  },
}));

export default ordersStore;
