import { useEffect } from "react";
import CreateProduct from "../components/CreateProductForm";
import UpdateProduct from "../components/UpdateProduct";
import ProductsList from "../components/ProductsList";
import productsStore from "../stores/productsStore";

export default function AdminPage(){
const store = productsStore();
  // Use effect
  useEffect(() => {
    store.fetchProducts();
  }, []);

  return (
    <div className="admin">

      <CreateProduct />
      <ProductsList/>
      <UpdateProduct/>
    </div>
  );

}