import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NotesPage from "../pages/notesPage";
import LoginPage from "../pages/LoginPage";
import {BrowserRouter,Routes,Route,Link} from "react-router-dom"
import RequireAuth from "./RequireAuth";
import SignUpPage from "../pages/SignUpPage";
import LogoutPage from "../pages/LogoutPage";
import { FaCartShopping } from "react-icons/fa6";
import { IoLogOutOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { CiShop } from "react-icons/ci";
import { FaHome } from "react-icons/fa";
import Badge from 'react-bootstrap/Badge';
import ordersStore from '../stores/ordersStore';
import { useEffect } from 'react';
import NoAuth from './NoAuth';
import IsAuth from './IsAuth';
import melina from '../images/melina.png';
import "../styles/flags.css"
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import Flags from 'country-flag-icons/react/3x2';
import { GB,IT } from 'country-flag-icons/react/3x2';
function FlagsComponent() {
  const languages = {

    en: {nativeName: 'English'},
    it: {nativeName: 'Italiano'}
  };
  const {t, i18n} = useTranslation();



//var Flags = Object.keys(languages).map((language)=>  {const Flag = Flags[language.toUpperCase]; <Flag></Flag>});

  return (

      <div className='flags-container'>



<div className='languages'>
                    {
          Object.keys(languages).map((language)=>  (<div style={{margin:'0.3vh'}} key={language}>{languages[language].nativeName}</div>))
        }
</div>
<div className='flags'>
        <GB onClick={()=>i18n.changeLanguage('en')} style={{margin:'1vh'}} ></GB>
        <IT onClick={()=>i18n.changeLanguage('it')} style={{margin:'1vh'}}></IT>
        </div >

      </div>
  );
}

export default FlagsComponent;