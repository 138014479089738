import productsStore from "../stores/productsStore";

export default function CreateProduct() {
  const store = productsStore();

  if (store.updateForm._id) return <></>;

  return (
    <div>
      <h2>Create product</h2>
      <form onSubmit={store.createProduct}>
        <input
          onChange={store.updateCreateFormField}
          value={store.createForm.title}
          name="title"
        />
        <textarea
          onChange={store.updateCreateFormField}
          value={store.createForm.description}
          name="description"
        />
        <input type="number" step="0.01"
          onChange={store.updateCreateFormField}
          value={store.createForm.price}
          name="price"
        />
        <button type="submit">Create product</button>
      </form>
    </div>
  );
}
