import create from "zustand";
import axios from "axios";

const productsStore = create((set) => ({
  products: null,
  product:null,
  createForm: {
    title: "",
    description: "",
    price:0.0,
  },

  updateForm: {
    _id: null,
    title: "",
    description: "",
    price:0.0,
  },

  fetchProducts: async () => {
    // Fetch the products
    const res = await axios.get("/products");

    // Set to state
    set({ products: res.data.products });
  },
  fetchProduct: async (_id) => {
    // Fetch the products

    const res = await axios.get(`/products/${_id}`);
    console.log(res.data);
    // Set to stateS
    set({ product: res.data.product });
    return res.data.product;
  },

  updateCreateFormField: (e) => {
    const { name, value } = e.target;

    set((state) => {
      return {
        createForm: {
          ...state.createForm,
          [name]: value,
        },
      };
    });
  },

  createProduct: async (e) => {
    e.preventDefault();

    const { createForm, products } = productsStore.getState();
    const res = await axios.post("/products", createForm);

    set({
      products: [...products, res.data.product],
      createForm: {
        title: "",
        description: "",
        price:0.0,
      },
    });
  },

  deleteProduct: async (_id) => {
    // Delete the product
    const res = await axios.delete(`/products/${_id}`);
    const { products } = productsStore.getState();

    // Update state
    const newProducts = products.filter((product) => {
      return product._id !== _id;
    });

    set({ products: newProducts });
  },

  handleUpdateFieldChange: (e) => {
    const { value, name } = e.target;

    set((state) => {
      return {
        updateForm: {
          ...state.updateForm,
          [name]: value,
        },
      };
    });
  },

  toggleUpdate: ({ _id, title, description,price }) => {
    set({
      updateForm: {
        title,
        description,
        price,
        _id,
      },
    });
  },

  updateProduct: async (e) => {
    e.preventDefault();

    const {
      updateForm: { title, description,price, _id },
      products,
    } = productsStore.getState();

    // Send the update request
    const res = await axios.put(`/products/${_id}`, {
      title,
      description,
        price,
    });

    // Update state
    const newProducts = [...products];
    const productIndex = products.findIndex((product) => {
      return product._id === _id;
    });
    newProducts[productIndex] = res.data.product;

    set({
      products: newProducts,
      updateForm: {
        _id: null,
        title: "",
        description: "",
        price:0.0,
      },
    });
  },
}));

export default productsStore;
