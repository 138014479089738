import productsStore from "../stores/productsStore";
import Button from 'react-bootstrap/Button';
import aglione from '../images/aglione1.jpg';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { useLocation } from "react-router-dom";


export default function Product({ product }) {

    var trimPrice = Number(product.price).toFixed(2);
  return (            
<Container>
<Row>
   <Col xs={6} md={4}>
       <h1>{product.title}</h1>
   </Col>
   <Col xs={6} md={4}>
   </Col>
   <Col xs={6} md={4}>
   </Col>
</Row>
<Row>
<Col xs={6} md={4}>
 <Image src={aglione} rounded fluid/>
</Col>
<Col xs={6} md={8}>
<Accordion defaultActiveKey="0">
<Accordion.Item eventKey="0">
<Accordion.Header>Ingredienti</Accordion.Header>
<Accordion.Body>
{product.description}
</Accordion.Body>
</Accordion.Item>
<Accordion.Item eventKey="1">
<Accordion.Header>Come viene prodotto</Accordion.Header>
<Accordion.Body>
 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
 eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
 minim veniam, quis nostrud exercitation ullamco laboris nisi ut
 aliquip ex ea commodo consequat. Duis aute irure dolor in
 reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
 pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
 culpa qui officia deserunt mollit anim id est laborum.
</Accordion.Body>
</Accordion.Item>
</Accordion>
</Col>
</Row>
<Row>
<Col xs={6} md={4}>
</Col>
<Col xs={6} md={4}>
{trimPrice}€
</Col>
<Col xs={6} md={4}>
<Button variant="outline-primary">Aggiungi al carrello</Button>{' '}
</Col>
</Row>
</Container>


  );
}