
import ProductDetails from "../components/ProductDetails"
import productsStore from "../stores/productsStore";
import { useEffect, useMemo } from "react";
import {  useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import aglione from '../images/aglione1.jpg';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

export default function ProductPage() {


  const store = productsStore();
  const { _id } = useParams();

  // Use effect
  useEffect(() => {
    store.fetchProducts();
  }, []);

    console.log(store);

  return (            
<Container>
{store.products &&
        store.products.filter((product)=>{ return product._id===_id}).map((product) => {
          return <ProductDetails product={product} key={product._id} />;
        })}


</Container>


  );
}

