import { useEffect } from "react";
import productsStore from "../stores/productsStore";
import Product from "./Product";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export default function Products() {
  const store = productsStore();
  // Use effect
  useEffect(() => {
    store.fetchProducts();
  }, []);
console.log(store.products);
  return (
    <Container>
<Row>
{store.products &&
        store.products.map((product) => {
          return <Product product={product} key={product._id} />;
        })}
</Row>
</Container>
  );
}
