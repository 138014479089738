import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import CloseButton from 'react-bootstrap/CloseButton';
import ordersStore from '../stores/ordersStore';
import { useEffect } from 'react';
import CartComponent from '../components/CartComponent';
import productsStore from '../stores/productsStore';
import RequireAuth from '../components/RequireAuth';
import PaypalComponent from '../components/PaypalComponent';

export default function CartPage() {
  const store = ordersStore();

  useEffect(() => {
    store.fetchOrders();
  }, []);
  console.log(store);

  return (<RequireAuth>
    <Container>
    <div>Carrello</div>
<Row>
<Col xs={6} md={2}>
</Col>
<Col xs={6} md={8}>
<Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Nome</th>
          <th>Prezzo</th>
          <th>Quantità</th>
        </tr>
      </thead>
      <tbody>
                {store.orders &&
        store.orders.map((order) => {
          console.log(order);
          return <CartComponent productId={order.product} key={order._id} quantity={order.quantity} />;
        })}
      </tbody>
      <PaypalComponent></PaypalComponent>
    </Table>
</Col>
<Col xs={6} md={2}>
</Col>
</Row>
</Container></RequireAuth>
  )
}
