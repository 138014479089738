import productsStore from "../stores/productsStore";
import Card from 'react-bootstrap/Card';
import {BrowserRouter,Routes,Route,Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import aglione from '../images/aglione1.jpg';
import DetectableOverflow from 'react-detectable-overflow';
import Col from 'react-bootstrap/Col';
import ordersStore from "../stores/ordersStore";
import Form from 'react-bootstrap/Form';
import { useState } from "react";

export default function Product({ product }) {
  var trimPrice = Number(product.price).toFixed(2);
  const [quantity, setQuantity] = useState(1);
  const store = productsStore((store) => {
    return { deleteProduct: store.deleteProduct, toggleUpdate: store.toggleUpdate};
  });
  const order = ordersStore((store) => {
    store.createForm.productId=product._id;
    store.createForm.quantity=quantity;
    return { createOrder: store.createOrder, productId: store.createForm.productId, quantity: store.createForm.quantity };
  });

  console.log(order);

  return (            
  <Col xs={6} md={4}>
    <div key={product._id}>
                        <Card tag="a" style={{ cursor: "pointer" }}>
                    <Link to={'/product/'+ product._id} state={{ productId: product._id }}>
            <Card.Img variant="top" src={aglione} />
            </Link>
            <Card.Body>
                <Card.Title>{product.title}</Card.Title>

                Quantità: <Form.Select size="sm" onChange={(e)=>{setQuantity(parseInt(e.target.value))}}>
      <option value={1}>1</option>
      <option value={2}>2</option>
      <option value={3}>3</option>
      </Form.Select>
                <Card.Text>
                Prezzo: {trimPrice}€
                </Card.Text>
                <Button variant="primary" onClick={order.createOrder}>Aggiungi al carrello</Button>
            </Card.Body>
            </Card>
      <button onClick={() => store.deleteProduct(product._id)}>Delete product</button>
      <button onClick={() => store.toggleUpdate(product)}>Update product</button>
    </div>
</Col>

  );
}