import productsStore from "../stores/productsStore";

export default function UpdateForm() {
  const store = productsStore();

  if (!store.updateForm._id) return <></>;

  return (
    <div>
      <h2>Update product</h2>
      <form onSubmit={store.updateProduct}>
        <input
          onChange={store.handleUpdateFieldChange}
          value={store.updateForm.title}
          name="title"
        />
        <textarea
          onChange={store.handleUpdateFieldChange}
          value={store.updateForm.description}
          name="description"
        />
        <button type="submit">Update product</button>
      </form>
    </div>
  );
}
