import productsStore from "../stores/productsStore";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import DetectableOverflow from 'react-detectable-overflow';
import Col from 'react-bootstrap/Col';
import ordersStore from "../stores/ordersStore";
import { useEffect,useState } from "react";
import CloseButton from "react-bootstrap/esm/CloseButton";

import CartRow from "./CartRow";

export default function CartComponent({productId, quantity,orderId }) {
    const [loading, setLoading] = useState(true);
    const store = productsStore();

    const [prod, setProd] = useState(store.product);

    useEffect(()=>{
        async function fetchData(){
            const res = await store.fetchProduct(productId);
            const data = await res;
            setProd(data);
            console.log(data);
        };

        fetchData().then(()=>setLoading(false));

    },[productId]);
  

    const orderStore = ordersStore();


    
    if (loading) {
        return (
            <tr>
                      <td>Loading...</td>      
            </tr>

        );
    }

  return (            
    <tr>
            <td><CloseButton onClick={() => {orderStore.deleteOrder(productId)}}/></td>
    <td>{prod.title}</td>
    <td>{prod.price}</td>
            {/* https://www.npmjs.com/package/react-bootstrap-input-spinner?activeTab=readme */}
    <td>{quantity}</td>
  </tr>
  );
}